import './WelcomePage.scss';
import React, { useContext, useState, Component, useEffect } from 'react';
import Constants from '../../constants';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Utils from '../../utils';
import Storage from '../../storage';
import queryString from 'query-string';
import Enums from '../../enums';
import TwoWayMessagePage from '../../Pages/TwoWayMessagePage/TwoWayMessagePage';
import LocationService from '../../Store/LocationsService/LocationsService';
import UserService from '../../Store/UserService';
import { LocationServiceContext } from '../../Store/LocationsService/LocationServiceProvider';
import SessionContext from '../../SessionContext';
import { useCookies } from 'react-cookie';

const WelcomePage = (props) => {
  const session = useContext(SessionContext);
  const [state, setstate] = useState({
    redirectToLogin: false,
    redirectToCoverSearch: false,
  });
  const { setAllLocations, setSelectedLocationKeys, setGCoverKey,setAllowedCoverKeysBookings,setAllowedCoverKeysRegForms } = useContext(
    LocationServiceContext
  );

  const getLocations = async () => {
    return new Promise((resolve) => {
      LocationService.getLocations().then((resp) => {
        try {
          if (resp) {
            const myLocations = LocationService.updateLocationStore(resp);
            setSelectedLocationKeys(myLocations);
            setAllLocations(myLocations);
  
            const myLocationKeys = LocationService.getCacheLocations();
            LocationService.updateLocKeys(myLocationKeys);
            resolve(true);
          }
        } catch (err) {}
      });
    });    
  };

  const [cookies, setCookie] = useCookies([Constants.sessionID]);

  const decodeSession = async (sessionIDParam) => {
    try {
      const secretCode = process.env.REACT_APP_API_KEY;
      var url = `${process.env.REACT_APP_AUTHENTICATION_API}/LoginSession?sessionID=${encodeURIComponent(sessionIDParam)}&secretCode=${secretCode}`;
      var res = await axios.post(url);
      const jwt = res.data;
      const jwtData = Utils.decodeJwt(jwt);
      let user = Storage.getItem(Constants.currUserKey);
      let sid = jwtData[Constants.sessionIDKey];

      Storage.setItem('userRole', jwtData.role);
      Storage.setItem('sessionID', sid);
      // Set Cookie
      setCookie(Constants.sessionID, sid, { withCredentials: true, SameSite: 'none', HttpOnly: true })
      
      let familyName = jwtData[Constants.familyName];
      Storage.setItem(Constants.familyName, familyName);

      if (!user) {
        user = {
          jwt: '',
        };
      }

      user.jwt = jwt;
      Storage.setItem(Constants.currUserKey, user);
      setGCoverKey(jwtData.CoverKey);

      const url2 = `${process.env.REACT_APP_CUSTOMER_API}/allCoverKeys`;
      const res2 = await axios.get(url2);

      if (res2?.data) {
        if (res2.data.length === 1 || jwtData.role.indexOf('admin') >= 0) {
          await UserService.getElementsForUserCoverKey();
          await getLocations();
        } else {
          setstate({ ...state, redirectToCoverSearch: true });
        }
      }

      // try {
      //     // Get Side Menu Restrictions
      //     const environment = process.env.REACT_APP_TRANSLATIONS_ENVIRONMENT
      //     const envi = (environment == 'local' || environment == "development") ? "Dev" : environment
      //     var applicationData = "$.EnableCoversBookings"
      //     var applicationName = "4PC.CustomerDashboardUI"
      //     url = `${process.env.REACT_APP_WEBSCHEDULER_API}/api/v1/Patient/getApplicationCoverByKey?key=${applicationData}&env=${envi}&applicationName=${applicationName}`;
      //      res = await axios.get(url);
      //     if (res?.data) {
      //       var data =  res.data.join(",")
      //       setAllowedCoverKeysBookings(data)
      //     }

      //       applicationData = "$.EnableCoversRegForms"
      //     url = `${process.env.REACT_APP_WEBSCHEDULER_API}/api/v1/Patient/getApplicationCoverByKey?key=${applicationData}&env=${envi}&applicationName=${applicationName}`;
      //     res = await axios.get(url);
      //     if (res?.data) {
      //       var data =  res.data.join(",")
      //       setAllowedCoverKeysRegForms(data)
      //     }    
      //   } catch (error) {
      //     console.log(error)
      //   }  

    } catch (error) {
      if (
        (error && !error.response) ||
        (error &&
          error.response.status ===
            Enums.HttpStatusCodes.httpStatusUnauthorizedError)
      ) {
        setstate({ ...state, redirectToLogin: true });
      } else if (
        (error && !error.response) ||
        (error &&
          error.response.status ===
            Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        window.location = '/';
      }
    }
  };

  const checkUser = async () => {
    const user = Storage.getItem(Constants.currUserKey);
    const secretCode = process.env.REACT_APP_API_KEY;
    if (user && user.jwt) {
      try {
        const jwtCheckUrl = `${process.env.REACT_APP_AUTHENTICATION_API}/jwtCheck`;
        await axios.get(jwtCheckUrl);
        // try {
        //     // Get Side Menu Restrictions
        //     const environment = process.env.REACT_APP_TRANSLATIONS_ENVIRONMENT
        //     const envi = (environment == 'local' || environment == "development") ? "Dev" : environment
        //     var applicationData = "$.EnableCoversBookings"
        //     var applicationName = "4PC.CustomerDashboardUI"
        //     var url = `${process.env.REACT_APP_WEBSCHEDULER_API}/api/v1/Patient/getApplicationCoverByKey?key=${applicationData}&env=${envi}&applicationName=${applicationName}`;
        //     var res = await axios.get(url);
        //     if (res?.data) {
        //       var data =  res.data.join(",")
        //       setAllowedCoverKeysBookings(data)
        //     }

        //       applicationData = "$.EnableCoversRegForms"
        //     url = `${process.env.REACT_APP_WEBSCHEDULER_API}/api/v1/Patient/getApplicationCoverByKey?key=${applicationData}&env=${envi}&applicationName=${applicationName}`;
        //     res = await axios.get(url);
        //     if (res?.data) {
        //       var data =  res.data.join(",")
        //       setAllowedCoverKeysRegForms(data)
        //     }    
        //   } catch (error) {
        //     console.log(error)
        //   }  

      } catch (error) {
        if (error.response !== undefined)
        {
            if (error.response.status === 401) {
              UserService.logout();
            }
        }
        window.location = '/';
      }
    } else {
      window.location = '/';
    }
  };

  useEffect(async () => {
    const value = queryString.parse(props.location.search);
    const sessionIDParam = value.SessionID;
    if (sessionIDParam) {      
      await decodeSession(sessionIDParam);
      session.SetValue({
        sessionID: sessionIDParam,
        sessionUpdated: new Date()
      });
    } else {
      checkUser();
    }
  }, []);

  return (
    <>
      <div className='page-cont'>
        <div className='center'>
          <h1>Welcome to your<br/>Patient Engagement<br/>platform</h1>
        </div>
      </div>
    </>
  );
};
export default WelcomePage;